import * as Sentry from '@sentry/vue';

export function initSentry(app) {
    // Check for .env variable SENTRY_FRONTEND_DSN
    if (typeof import.meta.env.VITE_SENTRY_FRONTEND_DSN !== 'string') {
        return;
    }

    let reportedSentryErrors = {};

    let tracesSampleRate = import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE ? parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE) : 0;

    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_FRONTEND_DSN,
        integrations: typeof import.meta.env.VITE_SENTRY_FRONTEND_DSN === 'string'
            ? [
                Sentry.extraErrorDataIntegration({ depth: 10 }),
                Sentry.browserTracingIntegration(),
                Sentry.inboundFiltersIntegration({
                    ignoreErrors: [
                        'Network Error',
                        'timeout of 0ms exceeded',
                        'Request failed with status code 401',
                        'Request failed with status code 402',
                        'Request failed with status code 403',
                        'Request failed with status code 404',
                        'Request failed with status code 419',
                        'Request failed with status code 422',
                        'Importing a module script failed',
                        'Failed to fetch dynamically imported module',
                    ],
                }),
            ]
            : [],
        tracesSampleRate,
        trackComponents: true,
        normalizeDepth: 11,
        ignoreErrors: [
            'Network Error',
            'timeout of 0ms exceeded',
            'Request failed with status code 401',
            'Request failed with status code 402',
            'Request failed with status code 403',
            'Request failed with status code 404',
            'Request failed with status code 419',
            'Request failed with status code 422',
            'Importing a module script failed',
            'Failed to fetch dynamically imported module',
        ],
        release: 'ks@' + import.meta.env.VITE_APP_VERSION,

        /* @see https://docs.sentry.io/error-reporting/configuration/filtering/?platform=browser#before-send */
        beforeSend: (event, hint) => {
            let message;

            // HTTP erorrs might just be a string
            if (typeof hint.originalException === 'string') {
                message = hint.originalException;
            }

            // Most other exceptions
            if (typeof hint.originalException === 'object') {
                message = hint.originalException.message;
            }

            // Send error/exception to Sentry
            if (!message) {
                return event;
            }

            // Only report exception/error once per page-view
            // Inspired by https://github.com/getsentry/sentry-javascript/issues/435
            if (message in reportedSentryErrors) {
                return;
            }

            reportedSentryErrors[message] = true;

            return event;
        },
    });
}
