export const until = async (condition) => {
    const poll = (resolve) => {
        if (condition()) {
            resolve();
        } else {
            setTimeout(() => poll(resolve), 100);
        }
    };

    return new Promise(poll);
};
