<template>
    <div>
        <div
            v-if="browserBanner.enabled"
            :class="[mobile ? 'site-warning-mobile' : 'site-warning', 'browser-warning']"
        >
            <fa-icon :icon="['fal', 'info-circle']" />&nbsp;
            <strong>{{ trans('shared.Din nettleser er utdatert og bør oppdateres!') }}</strong>
            <a href="https://support.kvalitetskontroll.no/nb/articles/3368085" target="_blank">
                {{ trans('shared.Les hvordan') }}
            </a>
        </div>

        <div
            v-if="warningBanner.enabled && !dismissedWarning"
            :class="[mobile ? 'site-warning-mobile' : 'site-warning']"
        >
            <fa-icon :icon="['fal', 'info-circle']" />&nbsp;
            <strong>{{ trans('shared.Informasjon') }}:</strong> {{ warningBanner.text }}
            <button class="close-button" @click="closeWarningBanner">
                <fa-icon :icon="['fal', 'times']" />
            </button>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex';
import { trans } from 'vue-components/src/mixin/trans.js';

export default {
    mixins: [trans],

    props: {
        bannerText: {
            type: String,
            default: '',
        },
        mobile: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            dismissed: false,
        };
    },

    computed: {
        ...mapState({
            warningBanner: state => state.client.warningBanner,
            browserBanner: state => state.client.browserBanner,
        }),
        dismissedWarning() {
            return this.dismissed
                ? true
                : document.cookie.split(';')
                    .some(item => item.trim().startsWith('ksWarningBannerDismissed'));
        },
    },

    created() {
        if (this.bannerText) {
            this.$store.dispatch('client/setWarningBanner', {
                enabled: true,
                text: this.bannerText,
            });
        }
    },

    methods: {
        closeWarningBanner() {
            let d = new Date();

            // Expire in 2 hours
            d.setHours(d.getHours() + 2);

            const expires = d.toUTCString();
            const cookie = `ksWarningBannerDismissed=true; expires=${expires}; path=/; SameSite=strict`;
            document.cookie = cookie;
            this.dismissed = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.site-warning {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    background-color: #e74c3c;
    box-sizing: border-box;
    color: white;
    display: -ms-flexbox;
    display: flex;
    font-size: 13px;
    justify-content: center;
    line-height: 14px;
    min-height: 25px;
    padding: 3px 5px;
    width: 100%;
    z-index: 10;
}
.site-warning strong {
    font-size: 14px;
    margin-right: 5px;
}
.site-warning button {
    background: none;
    color: white;
    border: none;
    font-size: 14px;
    position: absolute;
    right: 0;
    font-weight: bold;
    cursor: pointer;
}
.site-warning.browser-warning {
    margin-bottom: 1px;
}
.site-warning.browser-warning a {
    color: white;
    text-decoration-line: underline;
    text-decoration-color: white;
    font-size: 14px;
}

.site-warning-mobile {
    color: #e74c3c !important;
    padding: 10px 0;
    position: relative;
}
.site-warning-mobile .close-button {
    appearance: none;
    border: 1px solid lightgray;
    border-radius: 3px;
    position: absolute;
    top: 0;
    right: -15px;
}
.site-warning-mobile .close-button .fa-times {
    vertical-align: middle;
}
</style>
