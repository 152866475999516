import axios from 'axios';
import { until } from '@/libs/async-utils';

export function setNewCSRF(token) {
    if (typeof axios !== 'undefined') {
        axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
    }

    let meta = document.querySelector('meta[name="csrf-token"]');

    if (meta && meta.content) {
        meta.content = token;
    }

    // Check if jQuery is included on this page and set new CSRF token with the ajaxSetup()
    // method here. This is only needed for the desktop legacy pages.
    if (typeof window.jQuery !== 'undefined') {
        window.jQuery.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': token,
            },
        });
    }

    const tokenInputs = document.querySelectorAll('input[name=_token]');

    if (tokenInputs.length) {
        for (const tokenInput of tokenInputs) {
            tokenInput.setAttribute('value', token);
        }
    }
}

/**
 * Check if new CSRF token is available.
 * Should be added to a vue component's `method` object, so it gets access to Vue instance
 * and `this.$store`.
 * @export
 */
export function refreshCSRF() {
    let current = axios.defaults.headers.common['X-CSRF-TOKEN'];
    const timestamp = (new Date()).getTime();

    until(() => this.$store.state.user.user.id)
        .then(() => axios.get(`/refreshToken?t=${timestamp}`))
        .then((resp) => {
            const currentUserId = this.$store.state.user.user.id;

            if (resp.data.userId !== currentUserId) {
                this.$store.dispatch('setLoginTimedout', true);
            }

            if (resp.data.token && resp.data.token.length && current !== resp.data.token) {
                setNewCSRF(resp.data.token);

                // Have we been logged out though? Dispatch change to Vuex
                if (resp.data.userId === null) {
                    this.$store.dispatch('setLoginTimedout', true);
                }
            }

            if ('warning_banner' in resp.data) {
                const existingState = this.$store.state.client?.warningBanner;

                if (JSON.stringify(resp.data.warning_banner) === JSON.stringify(existingState)) {
                    return;
                }

                this.$store.dispatch('client/setWarningBanner', resp.data.warning_banner);
            }
        }, (error) => {
            try {
                Sentry.captureException(error);
            } catch (error) {
                console.warn('Sentry failed:  ', error);
            }

            console.error('CSRF refresh failed', error);
        });
}

export function refreshCSRFwithoutUserCheck() {
    let current = axios.defaults.headers.common['X-CSRF-TOKEN'];
    const timestamp = (new Date()).getTime();

    axios.get(`/refreshToken?t=${timestamp}`).then((resp) => {
        if (resp.data.token && resp.data.token.length && current !== resp.data.token) {
            setNewCSRF(resp.data.token);
        }

        if ('warning_banner' in resp.data) {
            const existingState = this.$store.state.client?.warningBanner;

            if (JSON.stringify(resp.data.warning_banner) === JSON.stringify(existingState)) {
                return;
            }

            this.$store.dispatch('client/setWarningBanner', resp.data.warning_banner);
        }
    }, () => {});
}
