<template>
    <kk-modal-v2 :close-button="false">
        <template #title>
            {{ trans('shared.Du har blitt logget ut') }}
        </template>

        <template #content>
            <div class="login-box">
                <kk-profile-picture :firstname="user.firstname" :lastname="user.lastname" :image="user.imageURL" />

                <div class="login-box-content">
                    <h3 class="name">
                        <span>{{ user.firstname }}</span> {{ user.lastname }}
                    </h3>

                    <div class="input-container">
                        <input
                            ref="password"
                            v-model="password"
                            type="password"
                            name="password"
                            autofocus
                            :placeholder="trans('messages.Passord')"
                            :class="['input', { warning: errors.password.hasError }]"
                            @keyup.enter="login"
                        >
                        <svg-icon name="lock" height="20" fill="none" />
                    </div>

                    <div v-if="user.hasTwoFactor" class="input-container">
                        <input
                            v-model="twofactor"
                            type="text"
                            name="twofactor"
                            autofocus
                            :placeholder="trans('messages.Tofaktorautentisering')"
                            :class="['input', { warning: errors.twofactor.hasError }]"
                            @keyup.enter="login"
                        >
                        <fa-icon :icon="['fal', 'mobile-alt']" size="2x" />
                    </div>

                    <h4 :class="['info', errors.password.hasError || errors.twofactor.hasError ? 'error' : '']">
                        <span v-if="errors.password.hasError" key="passError">{{ errors.password.message }}</span>
                        <span v-else-if="errors.twofactor.hasError" key="2FAError">{{ errors.twofactor.message }}</span>
                        <span v-else key="info">{{ trans('shared.Logg inn for å fortsette økten') }}</span>
                    </h4>
                    <kk-checkbox v-model="rememberMe" shape="square">
                        {{ trans('homepage.Husk meg') }}
                    </kk-checkbox>
                </div>
            </div>
        </template>

        <template #footer>
            <kk-button
                :desktop="desktop"
                :big="!desktop"
                full
                @click="login"
            >
                {{ trans('messages.Logg inn') }}
            </kk-button>
            <kk-button
                :desktop="desktop"
                :big="!desktop"
                full
                type="basic"
                @click="reload"
            >
                {{ trans('shared.Bytt bruker') }}
            </kk-button>
        </template>
    </kk-modal-v2>
</template>

<script>

import { kkButton, kkCheckbox, kkModalV2, kkProfilePicture, ks, svgIcon } from 'vue-components';
import { mapState } from 'vuex';
import { setNewCSRF } from '../../libs/csrf-token.js';

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMobileAlt } from '@fortawesome/pro-light-svg-icons';
library.add(faMobileAlt);

export default {
    name: 'login-popup',

    components: {
        kkButton,
        kkCheckbox,
        kkModalV2,
        kkProfilePicture,
        svgIcon,
    },

    props: {
        loginTimedout: {
            type: Boolean,
            default: false,
        },

        desktop: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        errors: {
            password: {
                message: '',
                hasError: false,
            },
            twofactor: {
                message: '',
                hasError: false,
            },
        },
        rememberMe: false,
        password: '',
        twofactor: '',
    }),

    computed: {
        ...mapState({
            user: state => state.user.user,
        }),
    },

    mounted() {
        this.password = '';
        this.twofactor = '';

        if (this.$refs.password) {
            this.$refs.password.focus();
        }
    },

    methods: {
        login() {
            this.errors.password.hasError = false;
            this.errors.twofactor.hasError = false;

            ks.post('/ajax/login', {
                userId: this.user.id,
                password: this.password,
                twofactor: this.twofactor,
                remember: Number(this.rememberMe), // needs to be 0 or 1
            }).then(
                (response) => {
                    // Run the setNewCSRF method if a new token is returned
                    if (response.data.token) {
                        setNewCSRF(response.data.token);
                    }

                    this.passwordError = false;
                    this.twofactorError = false;
                    this.$store.dispatch('setLoginTimedout', false);
                },
                (err) => {
                    if (err.data.password !== null) {
                        this.errors.password.hasError = true;
                        this.errors.password.message = err.data.password;
                    }

                    if (err.data.twofactor !== null) {
                        this.errors.twofactor.hasError = true;
                        this.errors.twofactor.message = err.data.twofactor;

                        if (err.data.twofactor === '__2FA__') {
                            this.errors.twofactor.message = this.trans('messages.Tofaktorautentisering mangler');
                        }
                    }
                },
            );
        },
        reload() {
            window.location.reload();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/variables';

.kk-modal :deep(.dialogbox) {
    width: 85%;
    max-width: 420px;

    .header {
        justify-content: center;
        padding-bottom: 15px;

        .title {
            margin: 0 20px 0 0;
        }
    }

    .footer {
        margin-top: 0;

        @media (max-width: 405px) {
            flex-direction: column;

            .kk-button {
                margin-left: 0;
            }
            .kk-button:not(:first-of-type) {
                margin-top: 10px;
            }
        }
    }
}

.kk-button {
    font-size: 14px !important;
    margin: 0 5px;
}

.login-box {
    display: flex;
    justify-content: center;

    .input-box {
        width: 290px;
    }

    .kk-profile-picture {
        height: 16vw;
        width: 16vw;
        max-width: 80px;
        max-height: 80px;
        flex-shrink: 0;
    }

    .login-box-content {
        width: 290px;
        .name {
            font-size: 25px;
            margin-bottom: 12px;
            margin-top: 0;
            font-weight: 300;
            span {
                font-weight: normal;
            }
        }

        .info {
            font-size: 12px;
            font-weight: 300;
            margin: 10px 0 0 0;
            &.error {
                color: var(--text-danger);
            }
        }

        .input-container {
            position: relative;
            margin-bottom: 10px;

            .input {
                appearance: none;
                height: 40px;
                width: calc(100% - 60px);
                padding: 0 20px 0 40px;
                border: 0;
                border-radius: 6px;
                box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
                font-size: 16px;

                &.warning {
                    box-shadow: 0 0 5px 0 $danger;
                    outline-color: var(--danger);
                }

                &::placeholder {
                    font-weight: 300;
                    font-weight: 15px;
                }
            }

            .svg-icon,
            svg {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                left: 12px;
                color: #ccc;
                display: flex;
                align-items: center;
            }
        }
    }
}

.kk-checkbox {
    display: inline-flex;
    margin-top: 10px;
    max-width: max-content;
}
</style>
