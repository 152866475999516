<template>
    <div class="kk-profile-picture">
        <img
            v-show="imageLoaded"
            class="user-image"
            :src="image"
            alt="profile image"
            @error="onImgError"
            @load="onImgLoad"
        >
        <span v-if="! imageLoaded" class="initials">
            {{ initials }}
        </span>
    </div>
</template>

<script>

export default {
    name: 'kk-profile-picture',

    props: {
        firstname: {
            default: null,
            type: String,
        },
        lastname: {
            default: null,
            type: String,
        },
        image: {
            default: '',
            type: String,
        },
    },

    data() {
        return {
            imageLoaded: false,
        };
    },

    computed: {
        /**
       * Return user's initials
       * John Doe returns "JD"
       */
        initials() {
            const first = this.firstname;
            const last = this.lastname;

            return (first ? first.substring(0, 1) : '') + (last ? last.substring(0, 1) : '');
        },
    },

    methods: {
        onImgError() {
            this.imageLoaded = false;
        },
        onImgLoad() {
            this.imageLoaded = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.kk-profile-picture {
    height: 40px;
    width: 40px;
    margin-right: 10px;
    .user-image {
        border-radius: 100%;
        width: 100%;
        height: 100%;
        background: #f1f1f1;
    }
    .initials {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background: #f1f1f1;
        color: #5a5a5a;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        text-transform: uppercase;
    }
}
</style>
