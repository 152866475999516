/**
 * We need to load babel-polyfill to rewrite some core functionality like
 * Promise / Array.from / Array.prototype.includes, findindex etc.
 * CONS; This makes the overall buildsize a bit larger.
 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Moment
import moment from 'moment/min/moment-with-locales';

// Separate init files
import { initSentry } from './sentry.js';
import { initServiceWorker } from './service-worker.js';
import fontAwesome from './font-awesome.js';
import { initMixpanel } from './mixpanel.js';
import SvgIcon from 'vue-components/src/components/svg-icon/svg-icon.vue';

import axios from 'axios';

// Axios setup
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Set up initial CSRF token in axios.
let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

const lang = document.querySelector('html').getAttribute('lang');

if (lang) {
    try {
        moment.locale(lang);
    } catch {
        // Catch - ie. just use the default languag (en)

        console.warn('Could not load moment locale: ', lang);
    }
}

try {
    initMixpanel();
} catch (error) {
    console.warn('Mixpanel failed:  ', error);
}

initServiceWorker();

window.moment = moment;

const setup = (app) => {
    // Font Awesome
    fontAwesome(app);

    // Add a global method to moment to return null if the date is invalid
    // This is useful for when we have a date that is not valid, and we want to display it in vue templates
    const originalFormat = moment.fn.format;

    moment.fn.format = function(format) {
        if (!this.isValid()) {
            return null;
        }

        return originalFormat.call(this, format);
    };
    app.config.globalProperties.$moment = window.moment;
    app.config.globalProperties.moment = window.moment;

    // Register global components
    app.component('svg-icon', SvgIcon);

    /* global process */
    if (process.env.NODE_ENV === 'production') {
        app.config.devtools = false;
        app.config.debug = false;
        app.config.silent = true;
    }

    try {
        initSentry(app);
    } catch (error) {
        console.warn('Sentry failed:  ', error);
    }
};
export default setup;
